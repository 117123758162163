<template>
  <div class="SamplePaperInspection">
    <template v-if="showIndex == 1">
      <refreshTime ref="refreshTime"></refreshTime>
      <div class="search-box">
        <div>
          学校筛选：
          <el-select
            v-model="schoolId"
            filterable
            placeholder=""
            @change="checkList"
          >
            <el-option label="全部" value="-1"> </el-option>
            <el-option
              v-for="item in schoolList"
              :key="item.schoolId"
              :label="item.schoolName"
              :value="item.schoolId"
            >
            </el-option>
          </el-select>

          题目：
          <el-select v-model="webId" placeholder="请选择" @change="checkList">
            <el-option
              v-for="item in questionOrderList"
              :key="item.webId"
              :disabled="item.isChild > 0"
              :label="item.label"
              :value="item.webId"
            >
            </el-option>
          </el-select>
          阅卷老师：
          <el-select
            v-model="teacherId"
            placeholder="请选择老师"
            @change="checkTeacher"
          >
            <el-option
              v-for="item in teacherList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          分数：
          <el-input
            v-model="score"
            placeholder=""
            oninput="value=value.replace(/[^0-9.]/g,'')"
            @blur="score = $event.target.value"
          ></el-input>
          分（满分<template v-if="nowQuestion.webId >= 0">{{
            nowQuestion.score
          }}</template
          >分）
          <el-button type="primary" plain @click="monitorCheckList"
            >搜索</el-button
          >
        </div>
        <el-button v-if="!teacherId" type="text" @click="showListPaper()">
          查看主观题{{ indexQuestion.value }}题抽查结果
        </el-button>
        <div v-if="teacherId && teacherList.length > 0">
          <template v-if="teacherId == -1">查看 </template>
          <template v-else>
            {{ teacherList.filter((item) => item.value == teacherId)[0].label }}
          </template>

          <el-button type="text" @click="showListPaper()"> 抽查结果 </el-button>
        </div>
      </div>
      <el-table
        v-loading="listLoading"
        :data="monitorCheckListData"
        border
        style="width: 100%"
      >
        <el-table-column prop="teacherName" label="阅卷教师" align="center">
        </el-table-column>
        <el-table-column prop="schoolName" label="学校" align="center">
          <template slot-scope="{ row }">
            {{ row.schoolName || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="rowNum" label="批阅顺序" align="center">
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="批阅时间"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="score"
          label="给分"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.againScore || scope.row.againScore == 0">
              {{ scope.row.againScore }}
            </template>
            <template v-else>{{ scope.row.score }}</template>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="抽查" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="showPaper(scope.row)">
              抽查
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :key="showIndex"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </template>
    <template v-if="showIndex != 1">
      <div class="header">
        <div class="i" @click="goList()">
          <i class="el-icon-arrow-left"></i>返回
        </div>
        <p>
          主观题{{ questionNum }} -
          <template v-if="teacherId">
            {{
              teacherList.filter((item) => item.value == teacherId)[0].label
            }}-</template
          >
          抽查结果
        </p>
        <div></div>
      </div>
      <el-table
        v-loading="listLoading"
        :data="monitorCheckResultData"
        border
        style="width: 100%"
      >
        <el-table-column prop="teacherName" label="阅卷教师" align="center">
        </el-table-column>
        <el-table-column prop="schoolName" label="学校" align="center">
          <template slot-scope="{ row }">
            {{ row.schoolName || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="rowNum" label="批阅顺序" align="center">
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="批阅时间"
          width="198px"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="score" label="给分" align="center">
        </el-table-column>
        <el-table-column prop="againScore" label="重评给分" align="center">
          <template slot-scope="scope">
            <template v-if="scope.row.againScore || scope.row.againScore == 0">
              {{ scope.row.againScore }}
            </template>
            <template v-else>-</template>
          </template>
        </el-table-column>
        <el-table-column prop="score" label="改分" align="center">
          <template slot-scope="scope">
            <template
              v-if="scope.row.changeScore || scope.row.changeScore == 0"
            >
              {{ scope.row.changeScore }}
            </template>
            <template v-else>-</template>
          </template>
        </el-table-column>
        <el-table-column prop="score" label="抽查结果" align="center">
          <template slot-scope="scope">
            <!-- <template v-if="scope.row.againScore || scope.row.againScore == 0">
              重评给分
            </template>
            <template v-else> -->
            <template v-if="!scope.row.state"> 未抽查 </template>
            <template v-if="scope.row.state == 1"> 通过 </template>
            <template v-if="scope.row.state == 2"> 打回 </template>
            <template v-if="scope.row.state == 3"> 改分 </template>
            <!-- </template> -->
          </template>
        </el-table-column>
        <el-table-column prop="score" label="处理结果" align="center">
          <template slot-scope="scope">
            <template v-if="scope.row.isHandle">已处理</template>
            <template v-else>-</template>
          </template>
        </el-table-column>
        <el-table-column
          prop="extractTime"
          label="抽查时间"
          width="198px"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="extractUserName" label="抽查人员" align="center">
        </el-table-column>
        <el-table-column prop="address" label="答题卡" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="showQuestionAw(scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :key="showIndex"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </template>
  </div>
</template>

<script>
import {
  monitorCheckList,
  monitorCheckResult,
  getQuestionTeacher,
  questionTeacher,
} from "@/core/api/exam/paperMonitor";
import { showSubject } from "@/core/util/util";
import { getObj, schoollistbyexamid } from "@/core/api/exam/exam";
import { getStore, setStore } from "@/core/util/store";
import refreshTime from "./components/refreshTime";
export default {
  name: "SamplePaperInspection",
  components: { refreshTime },
  data() {
    return {
      pageIndex: 1,
      pageSize: 50,
      total: 0,
      listLoading: false,
      examPaperId: "",
      teacherIdKey: "",
      paperList: [],
      teacherList: [],
      teacherId: "",
      questionNum: "",
      score: "",
      questionOrderList: [],
      monitorCheckListData: [],
      monitorCheckResultData: [],
      indexQuestion: {},
      nowQuestion: {},
      indexTeacher: {},
      showIndex: 1,
      schoolList: [],
      schoolId: "-1",
      webId: 0,
    };
  },

  created() {
    this.showIndex = 1;
    this.examPaperId = Number(this.$route.query.examPaperId);
    if (this.$route.query.markType == 2) {
      return;
    }
    // console.log(this.$route.params.webId);
    if (this.$route.params.webId === undefined) {
      this.setSearchList();
    } else {
      this.questionNum = this.$route.params.questionNum;
      this.score = this.$route.params.score;
      this.teacherId = this.$route.params.teacherId;
      this.webId = this.$route.params.webId;
      let data = {
        questionNum: this.questionNum,
        score: this.score,
        webId: this.webId,
        current: 1,
        pageSize: 50,
      };
      setStore({ name: "SamplePaperInspection_info", content: data, type: 1 });
    }

    this.getQuestionTeacher(1);
    // this.questionTeacher(2);
    this.getExamInfo();
    this.getSchoollistbyexamid();
    this.userInfo = getStore({ name: "userInfo" });
  },
  methods: {
    getNewItem() {
      this.setSearchList();
      this.getQuestionTeacher(1);
    },
    async getSchoollistbyexamid() {
      const res = await schoollistbyexamid({
        examId: this.$route.query.examId,
      });

      this.schoolList = res.data.data;
    },
    setSearchList() {
      let pageInfo = getStore({ name: "SamplePaperInspection_info" });

      if (
        pageInfo &&
        pageInfo.questionNum &&
        this.examPaperId == pageInfo.examPaperId
      ) {
        this.questionNum = pageInfo.questionNum || "";
        this.score = pageInfo.score;
        this.webId = pageInfo.webId;
        this.pageIndex = pageInfo.current;
        this.pageSize = pageInfo.size;
        this.schoolId = pageInfo.schoolId;
      }
    },
    goList() {
      this.showIndex = 1;
      this.pageIndex = 1;
      this.monitorCheckList();
    },
    // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      if (this.showIndex == 1) {
        this.monitorCheckList();
      } else {
        this.monitorCheckResult();
      }
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      if (this.showIndex == 1) {
        this.monitorCheckList();
      } else {
        this.monitorCheckResult();
      }
    },
    selectChange() {
      this.monitorCheckResult();
    },
    showSubject(v) {
      return showSubject(v, 8);
    },
    async questionTeacher() {
      let data = {
        examPaperId: this.examPaperId,
        questionNum: this.nowQuestion.value,
        sheetDetailId: this.nowQuestion.sheetDetailId,
        type: this.nowQuestion.type,
      };
      let pageInfo = getStore({ name: "SamplePaperInspection_info" });
      await questionTeacher(data).then((res) => {
        this.teacherList = [{ label: "所有老师", value: "-1" }];

        res.data.data.forEach((item) => {
          if (
            pageInfo &&
            pageInfo.examPaperId == this.examPaperId &&
            pageInfo.teacherId == item.id
          ) {
            this.teacherId = pageInfo.teacherId;
          }
          this.teacherList.push({
            label: item.name,
            value: item.id,
          });
        });

        this.teacherId = this.teacherId || "-1";
      });
    },
    monitorCheckResult() {
      let data = {
        examPaperId: this.examPaperId,
        questionNum: this.nowQuestion.value,
        type: this.nowQuestion.type,
        teacherId: this.teacherId,
        current: this.pageIndex,
        size: this.pageSize,
        sheetDetailId: this.nowQuestion.sheetDetailId,
        schoolId: this.schoolId,
      };

      this.listLoading = true;

      monitorCheckResult(data)
        .then((res) => {
          // this.monitorCheckResultData = res.data.data;
          this.listLoading = false;
          this.monitorCheckResultData = res.data.data.records;
          this.total = res.data.data.total;
          // console.log(res);
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    showListPaper() {
      this.pageIndex = 1;
      this.showIndex = 2;
      this.monitorCheckResult();
    },
    showQuestionAw(item) {
      let data = {
        imgUrl: item.answerUrl,
      };
      if (!item.answerUrl) {
        this.$message({
          message: "当前试题没有试题图片！",
          type: "warning",
        });
        return;
      }
      const routeData = this.$router.resolve({
        name: "ShowQuestionImgs",
        query: data,
      });
      window.open(routeData.href, "_blank");
    },
    showPaper(item) {
      let data = {
        id: item.id,
        examPaperId: item.examPaperId,
        questionNum: item.questionNum,
        teacherId: item.teacherId,
        sheetDetailId: item.sheetDetailId,
        fromUrl: "SamplePaperInspection",
      };
      if (item.questionNum) {
        this.questionOrderList.map((index) => {
          if (index.label == item.questionNum) {
            data.type = index.type;
          }
        });
      }
      this.$router.push({
        name: "ShowCheckPaper",
        query: data,
      });
    },
    monitorCheckList() {
      let data = {
        examPaperId: this.examPaperId,
        questionNum: this.nowQuestion.value,
        sheetDetailId: this.nowQuestion.sheetDetailId,
        type: this.nowQuestion.type,
        score: this.score,
        teacherId: this.teacherId,
        current: this.pageIndex,
        size: this.pageSize,
        schoolId: this.schoolId,
      };

      this.listLoading = true;
      data.webId = this.webId;
      setStore({ name: "SamplePaperInspection_info", content: data, type: 1 });
      monitorCheckList(data)
        .then((res) => {
          this.listLoading = false;
          this.monitorCheckListData = res.data.data.records;
          this.total = res.data.data.total;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    getExamInfo() {
      getObj(this.$route.query.examId).then((res) => {
        this.examInformation = res.data.data;
        res.data.data.paperList.map((item) => {
          this.paperList.push({
            value: item.paperId,
            label: item.subjectName,
            subjectId: item.subjectId,
          });
        });
      });
    },

    async checkList() {
      this.nowQuestion = this.questionOrderList.filter(
        (item) => item.webId == this.webId
      )[0];
      this.teacherId = "";
      await this.questionTeacher();
      this.monitorCheckList();
    },
    checkTeacher() {
      this.indexTeacher = this.teacherList.filter(
        (item) => item.key == this.teacherIdKey
      )[0];
      this.monitorCheckList();
    },
    async getQuestionTeacher(type) {
      let data = {
        examPaperId: this.examPaperId,
        type: type,
      };
      await getQuestionTeacher(data).then((res) => {
        if (type == 1) {
          this.questionOrderList = res.data.data.map((item, index) => {
            item.webId = index;
            return item;
          });
          if (!this.webId) {
            this.webId = this.questionOrderList[0].webId;
          }
          this.questionOrderList.map((index) => {
            if (index.webId == this.webId) {
              this.indexQuestion = index;
              this.nowQuestion = index;
            }
          });
        }
        // if (type == 2) {
        //   this.teacherList = [];
        //   let Array = [];
        //   res.data.data.forEach((item, index) => {
        //     item.key = index;
        //     if (Array.indexOf(item.value) == -1) {
        //       Array.push(item.value);
        //       this.teacherList.push(item);
        //     }
        //   });
        // }
      });

      await this.questionTeacher();
      this.monitorCheckList();
    },
  },
};
</script>
<style lang="scss" scoped>
.SamplePaperInspection {
  padding: 24px;
  .header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    .i {
      color: #5a5a5a;
    }
  }
  .search-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    .el-input {
      width: 80px;
      margin-right: 8px;
    }
    .el-select {
      margin: 0 24px 0 8px;
    }
    .subject-name {
      width: 120px;
    }
  }
}
</style>
